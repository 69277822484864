import React from 'react'

const Services = () => {
  return (

    <>

      <section>
        <div className='container-fluid text-white p-0 ALLBG' style={{ backgroundImage: `url(${require("../img/serviceBG.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
        </div>
      </section>

      <section>
        <div className="container p-lg-5 mt-5">
          <div className="YESEVA">
            <div className='p-lg-3 pb-4'  >
              <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "2px" }} > OUR HOTEL SERVICES </h2>
              <div className='mt-3'>
                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4 FontFamilyMontSerret p-lg-3">
            <div className="col-lg-4">
              <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-solid fa-location-dot" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200",textTransform:"uppercase" }} > Dry Cleaning  </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise dry clean woolens, wedding outfits and designer wear. </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-solid fa-user" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200",textTransform:"uppercase" }} >  Laundry Service </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise Premium laundry service.bed linens, blankets, pillows, throws, cushions, etc  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-sharp fa-solid fa-clock" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "400",textTransform:"uppercase" }} > Wash Service </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise Wash Service is Washing Machine Wash , Steam Press , Suitable for daily wears </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4 FontFamilyMontSerret p-lg-3">
            <div className="col-lg-4">
              <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-solid fa-car" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200",textTransform:"uppercase" }} > Shoe Cleaning </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > After a day out playing football, would you rather use them again, caked in mud ? </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-sharp fa-solid fa-money-bill" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200",textTransform:"uppercase" }} > Bag Cleaning </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Leather bags, totes, satchels, wallets, purses and laptop bags are items used every day and are a harbinger of dirt and germs </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-sharp fa-solid fa-phone" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "400",textTransform:"uppercase" }} > Curtain Cleaning </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > A good rule of thumb is to clean your blinds, curtains and drapes every 2 months cleaning. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container p-lg-5 mt-5">
          <div className="YESEVA">
            <div className='p-lg-3 pb-4'  >
              <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "2px" }} > CAR RENTAL SERVICES </h2>
              <div className='mt-3'>
                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4 FontFamilyMontSerret p-lg-3">
            <div className="col-lg-4">
              <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-solid fa-location-dot" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200" }} > PICK UP AND DROP </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise Tours and Travels Offer Doorstep pick up and drop. </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-solid fa-user" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200" }} > EXPERIENCED DRIVER </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise Tours and Travels provide Well supportive & experienced driver. </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-sharp fa-solid fa-clock" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "400" }} > 24X7 SERVICES </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise Tours and Travels provide 24x7 cab service. </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row g-4 FontFamilyMontSerret p-lg-3">
            <div className="col-lg-4">
              <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-solid fa-car" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200" }} > NO HIDDEN CHARGES </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise Tours and Travels do not charge any kind of hidden charges. </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-sharp fa-solid fa-money-bill" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200" }} > REASONABLE RATES </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > We provide the best travel deals with reasonable rates for tour packages on any travelling </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                <div className=' text-center p-2'>
                  <i class="fa-sharp fa-solid fa-phone" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                  <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "400" }} > CAB BOOKING </h2>
                  <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradaise Tours and Travels accept Online car booking and Booked cars wont get cancelled. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Services