import React from 'react'
import "./Comman.css"
import { NavLink } from "react-router-dom"

const Home = () => {

    return (

        <>

            <section>
                <div className="container-fluid  d-none d-lg-block d-block " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center m-0" style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9595009267 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to=''> <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9595009267  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to=''> <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  greenparadaise.com </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="container-fluid d-lg-none d-none " style={{ backgroundColor: "#295063" }} >
                    <div className="container"  >
                        <ul class=" d-flex justify-content-lg-center " style={{ padding: "14px" }} >
                            <li className=" d-flex justify-content-lg-center ps-lg-5 " >
                                <NavLink className="text-decoration-none" to='' > <i class="fa-sharp fa-light fa-mobile-button " style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > For Bookings (+91) 9595009267 </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} > Customer Care (+91) 9595009267  </span> </NavLink>
                            </li>
                            <li className=" d-flex justify-content-lg-center ps-lg-5" >
                                <NavLink className="text-decoration-none" to='' > <i class="fa-sharp fa-light fa-mobile-button" style={{ color: "#f8b239", fontSize: "20px" }} > </i> <span className='ms-lg-2 FontMontSerret BOOKINGHEADER' style={{ fontSize: "14px" }} >  greenparadaise.com  </span> </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <section>
                <div className="container-fluid p-0 " >
                    <div id="carouselExampleFade" className="carousel slide carousel-fade" data-bs-ride="carousel" >
                        <div className="carousel-inner FontFamilyMontSerret">
                            <div className="carousel-item active position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/hotel.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                                <div className='position-absolute p-5 translate-middle text-center text-white HOMEFIRSTCONTENT' style={{ backgroundColor: "rgba(0,0,0,0.65)" }} >
                                    <h2 className='pb-2' style={{ fontSize: "18px", letterSpacing: "2px" }} > WELCOME TO </h2>
                                    <h2 className='mt-2 HOMEFIRSTCONTENTH2' style={{ letterSpacing: "3px", fontSize: "31px" }} > Green Paradise  <br /> Chikhaldara , Badnera </h2>
                                </div>
                            </div>

                            <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/hotel2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>

                            </div>

                            <div className="carousel-item  position-relative SILDERHEIGHT" style={{ backgroundImage: `url(${require("../img/car2.png")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>

                            </div>

                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon  " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                            <span className="carousel-control-next-icon " style={{ height: "65px" }} aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>

            <section>
                <div className="container  mb-5 FontFamilyMontSerret">
                    <div className=" d-flex justify-content-center ">
                        <div className='p-3 pt-5 pb-5 text-center FIRSTCONTENT'  >
                            <h2 className='pb-2' style={{ color: "#282828", fontSize: "26px" }} >Retreat to our luxury hotel, perfectly positioned on Chikhaldara waterfront</h2>
                            <div className='d-flex justify-content-center p-2'>
                                <div className='' style={{ borderTop: "2px solid black", width: "12%" }}>
                                </div>
                            </div>
                            <p className='text-center pt-2' style={{ color: "#666", fontSize: "16px" }} > Hotel Green Paradise , Chikhaldara  is perfectly located on the water’s edge and will offer expansive views across Hotel Green Paradaise Lighter Basin and out to the Hotel Green Paradaise. Our sophisticated waterfront residence has 195 guestrooms, four restaurants and bars, versatile and elegant event spaces, a day spa, a 25-metre infinity pool, and a full fitness center. Enjoy a luxurious, 5-star experience with us.    </p>
                        </div>
                    </div>

                    <div className="row g-4 pb-3 p-lg-3">
                        <div className="col-lg-4">
                            <div className='shadow-lg h-100'>
                                <img className='w-100 ' src={require("../img/col1.png")} alt="emp1" title='emp-1' />
                                <div className='p-3 text-center'>
                                    <h2 className='YESEVA mt-2' style={{ fontSize: "22px", color: "#282828" }} > 500 reasons to stay somewhere new</h2>
                                    <div className='d-flex justify-content-center p-2'>
                                        <div className='mt-1 mb-1' style={{ borderTop: "2px solid black", width: "18%" }}>
                                        </div>
                                    </div>
                                    <p className='FontFamilyMontSerret mt-2' style={{ fontSize: "88.5%", color: "#282828" }} >Explore new places and reward your spirit of adventure. Now you can earn 500 Bonus Points for qualifying nights at new Green Paradise hotels around the world.</p>
                                    <NavLink to="/hotelorcars">
                                        <button className='p-2 mt-2 ' style={{ color: "#0072ce", backgroundColor: "#fff", border: "none" }} > <b className='p-2' style={{ fontWeight: "600" }} > SEE DETAILS <i class="ms-2 fa-sharp fa-solid fa-angle-right"></i> </b> </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className='shadow-lg h-100'>
                                <img className='w-100 ' src={require("../img/col2.png")} alt="emp1" title='emp-1' />
                                <div className='p-3 text-center'>
                                    <h2 className='YESEVA mt-2' style={{ fontSize: "22px", color: "#282828" }} > FIND experiences </h2>
                                    <div className='d-flex justify-content-center p-2'>
                                        <div className='mt-1 mb-1' style={{ borderTop: "2px solid black", width: "18%" }}>
                                        </div>
                                    </div>
                                    <p className='FontFamilyMontSerret mt-2' style={{ fontSize: "88.5%", color: "#282828" }} > Learn something new, do the unexpected, or just hit pause. More than 200 experiences await—all with your wellbeing in mind. Find what inspires you and earn 10 points per eligible $1 spent. ​</p>
                                    <NavLink className='' to="/hotelorcars">
                                        <button className='p-2 mt-lg-4 pt-3 ' style={{ color: "#0072ce", backgroundColor: "#fff", border: "none" }} > <b className='p-2' style={{ fontWeight: "600" }} > LEARN MORE  ​  <i class="ms-2 fa-sharp fa-solid fa-angle-right"></i> </b> </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className='shadow-lg h-100'>
                                <img className='w-100 ' src={require("../img/col3.png")} alt="emp1" title='emp-1' />
                                <div className='p-3 text-center'>
                                    <h2 className='YESEVA mt-2' style={{ fontSize: "22px", color: "#282828" }} > Moments of historic proportions​ </h2>
                                    <div className='d-flex justify-content-center p-2'>
                                        <div className='mt-1 mb-1' style={{ borderTop: "2px solid black", width: "18%" }}>
                                        </div>
                                    </div>
                                    <p className='FontFamilyMontSerret mt-2' style={{ fontSize: "90.5%", color: "#282828" }} > Masterpieces Stories are a curated collection of 30+ hotels that let you take your rightful place in history.​ </p>
                                    <NavLink to="/hotelorcars">
                                        <button className='p-2 mt-lg-5 pt-2  ' style={{ color: "#0072ce", backgroundColor: "#fff", border: "none" }} > <b className='p-2' style={{ fontWeight: "600" }} > MAKE HISTORY <i class="ms-2 fa-sharp fa-solid fa-angle-right"></i> </b> </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row  d-flex justify-content-center align-items-center p-lg-4">
                        <div className="col-lg-8 p-lg-0">
                            <NavLink className='text-decoration-none' to='/hotelorcars'>
                                <div>
                                    <img className='w-100' style={{ height: "20rem" }} src={require("../img/col4.png")} alt="emp1" title='emp-1' />
                                </div>
                            </NavLink>
                        </div>
                        <div className="col-lg-4 p-lg-0" style={{ height: "20rem" }}>
                            <div className='shadow-lg d-flex justify-content-center align-items-center h-100' >
                                <div className='p-3 text-center'>
                                    <h2 className='YESEVA mt-2' style={{ fontSize: "25px", color: "#282828" }} > The perfect pace:Green Paradise resorts </h2>
                                    <div className='d-flex justify-content-center p-2'>
                                        <div className='mt-1 mb-1' style={{ borderTop: "2px solid black", width: "18%" }}>
                                        </div>
                                    </div>
                                    <p className='FontFamilyMontSerret mt-2' style={{ fontSize: "89.5%", color: "#282828" }} > Get away without ever having to leave when you stay at a Green Paradise resort and enjoy an idyllic escape your way. </p>
                                    <NavLink to="/hotelorcars">
                                        <button className='p-2 mt-2 ' style={{ color: "#0072ce", backgroundColor: "#fff", border: "none" }} > <b className='p-2' style={{ fontWeight: "600" }} > LEARN MORE <i class="ms-2 fa-sharp fa-solid fa-angle-right"></i> </b> </button>
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section>
                <div className="container">
                    <div className=" d-flex justify-content-center FontFamilyMontSerret">
                        <div className='p-3 text-center FIRSTCONTENT' >
                            <h2 className='pb-2' style={{ color: "#282828", fontSize: "25px" }} > COMPLETE RANGE OF WELL MAINTAINED CARS </h2>
                            <div className='d-flex justify-content-center p-2'>
                                <div className='' style={{ borderTop: "2px solid black", width: "12%" }}>
                                </div>
                            </div>
                            <p className='text-center pt-2' style={{ color: "#666", fontSize: "16px" }} >  We have wide range of Vehicles for Outstation Tours on hire basis. All Vehicles are well Maintained with Experienced Drivers.  </p>
                        </div>
                    </div>

                    <div className="row g-4 p-lg-3 d-flex justify-content-center align-items-center">
                        <div className="col-lg-3 col-md-6">
                            <NavLink className='text-decoration-none' to='/hotelorcars'>
                                <div className='pt-2 pb-2'>
                                    <img className='w-100 CARIMG' src={require("../img/CAR1.png")} alt="emp1" title='emp-1' />
                                    <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                                        <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                                        <div className="row d-flex justify-content-around">
                                            <div className='col-lg-8 col-8'>
                                                <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                                                <div className='row'>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-4'>
                                                <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                                                <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                            <NavLink className='text-decoration-none' to='/hotelorcars'>
                                <div className='pt-2 pb-2'>
                                    <img className='w-100 CARIMG' src={require("../img/CAR5.png")} alt="emp1" title='emp-1' />
                                    <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                                        <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                                        <div className="row d-flex justify-content-around">
                                            <div className='col-lg-8 col-8'>
                                                <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                                                <div className='row'>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-4'>
                                                <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                                                <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                            <NavLink className='text-decoration-none' to='/hotelorcars'>
                                <div className='pt-2 pb-2'>
                                    <img className='w-100 CARIMG' src={require("../img/CAR3.png")} alt="emp1" title='emp-1' />
                                    <div className='p-2 ' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                                        <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                                        <div className="row d-flex justify-content-around">
                                            <div className='col-lg-8 col-8'>
                                                <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                                                <div className='row'>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-4'>
                                                <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                                                <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-3 col-md-6 ">
                            <NavLink className='text-decoration-none' to='/hotelorcars'>
                                <div className='pt-2 pb-2'>
                                    <img className='w-100 CARIMG' src={require("../img/CAR4.png")} alt="emp1" title='emp-1' />
                                    <div className='p-2' style={{ backgroundColor: "#fff", borderBottom: "1px solid #009688", boxShadow: "0 0 39px 10px gray" }} >
                                        <h6 className=' text-center FontFamilyMontSerret' style={{ fontSize: "14px", color: "#444" }} >SWIFT DZIRE (SEDAN)</h6>
                                        <div className="row d-flex justify-content-around ">
                                            <div className='col-lg-8 col-8'>
                                                <h6 className='ps-4' style={{ fontSize: "10px", color: "#747d7e" }} >SEATING: 4 PASSENGERS</h6>
                                                <div className='row'>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-solid fa-star" style={{ fontSize: "11px", color: "#428bca" }} > </i> </h6>
                                                    </div>
                                                    <div className='col-lg-1 col-sm-1 col-1'>
                                                        <h6><i class="fa-sharp fa-regular fa-star" style={{ fontSize: "11px", color: "#428bca" }} ></i></h6>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-4'>
                                                <h6 className='' style={{ color: "#939393" }} > | <b className='mb-1 ms-2' style={{ color: "#f44336", fontWeight: "600", fontSize: "18px" }} > Rs </b> </h6>
                                                <h6 className='ps-3' style={{ color: "#939393", fontWeight: "600", fontSize: "11px" }} >PER KM</h6>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </NavLink>
                        </div>

                    </div>

                    <div className='d-flex justify-content-center pt-4 FontFamilyMontSerret'>
                        <NavLink to="/hotelorcars">
                            <button className='p-2 w-100 BOOKNOW'  > <b className='' style={{ fontWeight: "400" }} > BOOK NOW </b> </button>
                        </NavLink>
                    </div>

                </div>
            </section>

            <section>
                <div className="container p-lg-3 mt-5 mb-5">
                    <div className="YESEVA">
                        <div className='p-lg-3 pb-4 mb-4'  >
                            <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "2px" }} > OUR HOTEL SERVICES </h2>
                            <div className='mt-3'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 FontFamilyMontSerret p-lg-3">
                        <div className="col-lg-4">
                            <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                                <div className=' text-center p-2'>
                                    <i class="fa-solid fa-location-dot" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                    <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200", textTransform: "uppercase" }} > Dry Cleaning  </h2>
                                    <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise dry clean woolens, wedding outfits and designer wear. </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                <div className=' text-center p-2'>
                                    <i class="fa-solid fa-user" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                    <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200", textTransform: "uppercase" }} >  Laundry Service </h2>
                                    <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise Premium laundry service.bed linens, blankets, pillows, throws, cushions, etc  </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                <div className=' text-center p-2'>
                                    <i class="fa-sharp fa-solid fa-clock" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                    <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "400", textTransform: "uppercase" }} > Wash Service </h2>
                                    <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise wash service is Washing Machine Wash , Steam Press , Suitable for daily wears </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 FontFamilyMontSerret p-lg-3">
                        <div className="col-lg-4">
                            <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                                <div className=' text-center p-2'>
                                    <i class="fa-solid fa-car" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                    <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200", textTransform: "uppercase" }} > Shoe Cleaning </h2>
                                    <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > After a day out playing football, would you rather use them again, caked in mud ? </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                <div className=' text-center p-2'>
                                    <i class="fa-sharp fa-solid fa-money-bill" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                    <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200", textTransform: "uppercase" }} > Bag Cleaning </h2>
                                    <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Leather bags, totes, satchels, wallets, purses and laptop bags are items used every day and are a harbinger of dirt and germs </p>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4">
                            <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                <div className=' text-center p-2'>
                                    <i class="fa-sharp fa-solid fa-phone" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                    <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "400", textTransform: "uppercase" }} > Curtain Cleaning </h2>
                                    <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > A good rule of thumb is to clean your blinds, curtains and drapes every 2 months cleaning. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container p-lg-3">
                    <div className="YESEVA">
                        <div className='p-lg-3 pb-lg-4 mb-4'  >
                            <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "2px" }} > CAR RENTAL SERVICES </h2>
                            <div className='mt-3'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 FontFamilyMontSerret p-lg-3">
                        <div className="col-lg-4">
                            <NavLink className='text-decoration-none' to='/services'>
                                <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                                    <div className=' text-center p-2'>
                                        <i class="fa-solid fa-location-dot" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                        <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200" }} > PICK UP AND DROP </h2>
                                        <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise Tours and Travels Offer Doorstep pick up and drop. </p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4">
                            <NavLink className='text-decoration-none' to='/services'>
                                <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                    <div className=' text-center p-2'>
                                        <i class="fa-solid fa-user" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                        <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "200" }} > EXPERIENCED DRIVER </h2>
                                        <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise Tours and Travels provide Well supportive & experienced driver. </p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4">
                            <NavLink className='text-decoration-none' to='/services'>
                                <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                    <div className=' text-center p-2'>
                                        <i class="fa-sharp fa-solid fa-clock" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                        <h2 className=' mt-3 pt-2' style={{ fontSize: "25px", color: "#000", fontWeight: "400" }} > 24X7 SERVICES </h2>
                                        <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise Tours and Travels provide 24x7 cab service. </p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>

                    <div className="row g-4 FontFamilyMontSerret p-lg-3">
                        <div className="col-lg-4">
                            <NavLink className='text-decoration-none' to='/services'>
                                <div className='shadow-lg p-5 h-100 d-flex justify-content-center align-items-center  p-3'>
                                    <div className=' text-center p-2'>
                                        <i class="fa-solid fa-car" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                        <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200" }} > NO HIDDEN CHARGES </h2>
                                        <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise Tours and Travels do not charge any kind of hidden charges. </p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4">
                            <NavLink className='text-decoration-none' to='/services'>
                                <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                    <div className=' text-center p-2'>
                                        <i class="fa-sharp fa-solid fa-money-bill" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                        <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "200" }} > REASONABLE RATES </h2>
                                        <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > We provide the best travel deals with reasonable rates for tour packages on any travelling </p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                        <div className="col-lg-4">
                            <NavLink className='text-decoration-none' to='/services'>
                                <div className='shadow-lg p-4 h-100 d-flex justify-content-center align-items-center p-3'>
                                    <div className=' text-center p-2'>
                                        <i class="fa-sharp fa-solid fa-phone" style={{ color: "#f26722", fontSize: "55px" }} ></i>
                                        <h2 className=' mt-3 pt-2' style={{ fontSize: "23px", color: "#000", fontWeight: "400" }} > CAB BOOKING </h2>
                                        <p className=' mt-3' style={{ fontSize: "14px", color: "#222" }} > Hotel Green Paradise Tours and Travels accept Online car booking and Booked cars wont get cancelled. </p>
                                    </div>
                                </div>
                            </NavLink>
                        </div>

                    </div>

                </div>
            </section>

        </>
    )
}

export default Home