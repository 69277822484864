import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Header from './Header'
import Footer from './Footer'
import Services from './Services'
import HotelsOrCars from './HotelsOrCars'
import Rooms from './Rooms'
import Contact from './Contact'
import Booking from './Booking'
import Photo from './Photo'
import Error from './Error'
import HotelBooking from './HotelBooking'



const Routing = () => {
    return (
        <>

            <React.Fragment>

                <header className='sticky-top'>
                    <Header />
                </header>

                <main>
                    <Routes>
                        <Route to path="/" element={<Home />} />
                        <Route to path="/hotelorcars" element={<HotelsOrCars />} />
                        <Route to path="/rooms" element={<Rooms />} />
                        <Route to path="/contactus" element={<Contact />} />
                        <Route to path="/booking" element={<Booking />} />
                        <Route to path="/hotelbooking" element={<HotelBooking />} />
                        <Route to path="/photo" element={<Photo />} />
                        <Route to path="/services" element={<Services />} />
                        <Route to path="*" element={<Error />} />
                    </Routes>
                </main>

                <footer>
                    <Footer/>
                </footer>

            </React.Fragment>

        </>
    )
}

export default Routing