import React from 'react'

const Photo = () => {
    return (
        <>

            <section>
                <div className='container-fluid text-white p-0 ALLBG' style={{ backgroundImage: `url(${require("../img/photoBG.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
                </div>
            </section>

            <section>
                <div className="container p-lg-5">
                    <div className="FontFamilyMontSerret ">
                        <div className='p-3 text-center pb-3 mt-2'  >
                            <h2 className='YESEVA' style={{ color: "#282828", fontSize: "33px", fontWeight: "800", letterSpacing: "2px" }} > Photos </h2>
                            <div className='mt-3 pb-3 d-flex justify-content-center'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                            <p>Consider this your new travel guide. Here’s a look at how our guests experience life.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container FontFamilyMontSerret p-lg-4 pb-4">
                    <div className="YESEVA pb-4">
                        <div className='p-lg-3 pb-3 mt-2'  >
                            <h2 className='' style={{ color: "#282828", fontSize: "33px", fontWeight: "800", letterSpacing: "2px" }} > Hotel </h2>
                            <div className='mt-3'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 p-lg-3">
                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomHOTEl1.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Night Exterior </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomHOTEl2.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} >Day Exterior</h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomHOTEl3.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Lobby </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomHOTEl4.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Reception </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomHOTEl5.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Lobby Art </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomHOTEl6.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Exterior </h2>
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section>
                <div className="container FontFamilyMontSerret p-lg-4 pb-4">
                    <div className="YESEVA pb-4">
                        <div className='p-lg-3 pb-3 mt-2'  >
                            <h2 className='' style={{ color: "#282828", fontSize: "33px", fontWeight: "800", letterSpacing: "2px" }} > ROOMS </h2>
                            <div className='mt-3'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 p-lg-3">
                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomROOMS1.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > 1 King Bed </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomROOMS2.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Twin Harbour Room </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container FontFamilyMontSerret p-lg-4 pb-4">
                    <div className="YESEVA pb-4">
                        <div className='p-lg-3 pb-3 mt-2'  >
                            <h2 className='' style={{ color: "#282828", fontSize: "33px", fontWeight: "800", letterSpacing: "2px" }} > Suites </h2>
                            <div className='mt-3'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 p-lg-3">
                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Park Suite Bedroom </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite2.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Park Suite Bathroom </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite3.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Chairman's Suite </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite4.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Executive Rooftop Suite </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite5.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Chairman's Suite Living Area </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite6.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Chairman's Suite Bathroom </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite7.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Chairman's Suite Kitchen </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite8.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Executive Rooftop Suite Living Area </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/rosuite9.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Executive Rooftop Suite Bathroom </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container FontFamilyMontSerret p-lg-4 pb-4">
                    <div className="YESEVA pb-4">
                        <div className='p-lg-3 pb-3 mt-2'  >
                            <h2 className='' style={{ color: "#282828", fontSize: "33px", fontWeight: "800", letterSpacing: "2px" }} > Dining </h2>
                            <div className='mt-3'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 p-lg-3">
                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/dining1.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Onemata Restaurant </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/dining2.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > The Living Room </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/dining3.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > The Living Room with View </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/dining4.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Captains Bar </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/dining5.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Captains Bar with Fire Pit </h2>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/dining6.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > The Pantry </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="container FontFamilyMontSerret p-lg-4 pb-4">
                    <div className="YESEVA pb-4">
                        <div className='p-lg-3 pb-3 mt-2'  >
                            <h2 className='' style={{ color: "#282828", fontSize: "33px", fontWeight: "800", letterSpacing: "2px" }} > Fitness </h2>
                            <div className='mt-3'>
                                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row g-4 p-lg-3">
                        <div className="col-lg-4 col-md-6">
                            <img className='w-100' style={{ height: "14rem" }} src={require("../img/roomFITNES1.png")} alt="emp1" title='emp-1' />
                            <div className='mt-2 '>
                                <h2 className='' style={{ fontSize: "87.5%", color: "#282828" }} > Fitness Center </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Photo