import React from 'react'
import { NavLink } from "react-router-dom"
import "./Comman.css"

const Contact = () => {
  return (

    <>

      <section>
        <div className='container-fluid text-white p-0 ALLBG' style={{ backgroundImage: `url(${require("../img/contactBG.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
        </div>
      </section>

      <section>
        <div className="container p-lg-4  pb-lg-5 mb-5" >
          <div className='pb-lg-3 mb-5'>
            <h2 className='text-center YESEVA' style={{ fontSize: "30px", color: "#282828" }} > Get In Touch </h2>
            <h6 className='text-center pt-1' style={{ fontSize: "20px", color: "#100e0e", lineHeight: "1.6", letterSpacing: "2px" }} > We are here and ready to help </h6>
          </div>
          <div className="row d-flex p-lg-4 justify-content-center g-2 p-2 FontFamilyMontSerret">

            <div className='col-lg-6 ' >
              <div className='p-lg-2'>
                <div>
                  <h6 className='pb-2 YESEVA' style={{ fontSize: "25px", color: "#282828" }} >Contact Address</h6>
                  <p className='pb-3' style={{ fontSize: "16px", lineHeight: "1.6" }} >Call for Hotel Booking and Car Rental , Taxi for  Outstation <br /> Tour or for Local  Travel.We have   wide range of  <br /> Vehicles with us for your service.</p>
                </div>

                <div className='mb-3 pb-1'>
                  <h4 className='pb-2 YESEVA' style={{ fontSize: "20px", fontWeight: "800", color: "#282828", letterSpacing: "1px" }} >Address : </h4>
                  <h6 className='' style={{ fontSize: "16px", lineHeight: "1.8" }} > Hotel Green Paradaise <br /> Chikhaldara   ( Badnera ) , Amravati 444807 </h6>
                </div>

                <div className='mt-3 pb-2'>
                  <span className='YESEVA' style={{ fontSize: "20px", fontWeight: "800", color: "#282828", letterSpacing: "1px" }} >Email :
                    <NavLink className='text-decoration-none text-dark FontFamilyMontSerret' to="">  <span className='ps-1' style={{ fontSize: "17px", fontWeight: "400" }} > greenparadaise.com </span></NavLink>
                  </span>
                </div>

                <div className='mt-3 mb-2' >
                  <span className='YESEVA' style={{ fontSize: "20px", fontWeight: "800", color: "#282828", letterSpacing: "1px" }} >Mobile No :
                    <NavLink className='text-decoration-none text-dark FontFamilyMontSerret' to="">  <span className='ps-1' style={{ fontSize: "17px", fontWeight: "400" }} > +91 9527468898 </span> </NavLink>
                  </span>
                </div>

              </div>
            </div>

            <div className='col-lg-5  p-0'>
              <div className='m-0'>
                <iframe className='w-100 m-0' title="gmap" style={{ height: "420px" }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d206470.11675755034!2d73.70405255586778!3d18.48319361399898!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2bf285b81dd75%3A0x344ffe59f81b4da1!2sHotel Green Paradaise%20Technology!5e0!3m2!1sen!2sin!4v1695706876360!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                </iframe>
              </div>
            </div>

          </div>
        </div>
      </section>

    </>

  )
}

export default Contact