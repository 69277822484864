import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Header.css"

const Header = () => {
    return (
        <>

            <nav id="navbar_top" className="navbar  navbar-expand-lg m-0 p-0" style={{ backgroundColor: "#151515" }} >
                <div className="container p-3 ">
                    <div className=''>
                        <NavLink to="/">
                            <img className='w-100' style={{ height: "3.6rem" }} src={require("../img/LOGO2.png")} alt="icloudsoft_logo" />
                        </NavLink>
                    </div>
                    <button className="navbar-toggler me-3 p-lg-2 bg-light" type="button " data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fa-solid fa-bars"></i>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <div className='d-flex justify-content-end pt-2 w-100  '>
                            <ul className="navbar-nav FontMontSerret d-flex justify-content-end align-items-lg-center w-100  " >

                                <li className='nav-item ps-lg-1 pe-lg-4'>
                                    <NavLink className=" HEADERHOVEREFFECT"  to="/" > HOME </NavLink>
                                </li>

                                <li className='nav-item ps-lg-1 pe-lg-4' >
                                    <NavLink className="HEADERHOVEREFFECT"  to="/hotelorcars"> HOTEL + CARS </NavLink>
                                </li>

                                <li className='nav-item ps-lg-1 pe-lg-4' >
                                    <NavLink className="HEADERHOVEREFFECT"  to="/rooms"> ROOMS </NavLink>
                                </li>

                                <li className='nav-item ps-lg-1 pe-lg-4' >
                                    <NavLink className="HEADERHOVEREFFECT"  to="/photo"> PHOTOS  </NavLink>
                                </li>

                                <li className='nav-item ps-lg-1 pe-lg-4' >
                                    <NavLink className="HEADERHOVEREFFECT"  to="/services"> SERVICES </NavLink>
                                </li>

                                <li className='nav-item ps-lg-1 pe-lg-4'>
                                    <NavLink className="HEADERHOVEREFFECT"  to="/contactus"> CONTACT </NavLink>
                                </li>

                                <li className='mt-1'>
                                    <NavLink to="/">
                                        <button className='p-2  HEDERBOOKNOW'  > <b className=' p-1' style={{ fontWeight: "400" }} > BOOK NOW </b> </button>
                                    </NavLink>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
            </nav>

        </>
    )
}

export default Header