import React from 'react'
import { NavLink } from 'react-router-dom'

const HotelsOrCars = () => {
  return (
    <>

      <section >
        <div className='container-fluid text-white p-0 ALLBG' style={{ backgroundImage: `url(${require("../img/HotelorRoomBG.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
        </div>
      </section>

      <section>
        <div className="">
          <div className="container p-lg-4 pt-4 FontFamilyMontSerret" >
            <div className="row pt-lg-5  d-flex justify-content-lg-around align-items-lg-center">
              <div className="col-lg-6 col-md-6">
                <div>
                  <img className='w-100' src={require("../img/hotel1.png")} alt="emp1" title='emp-1' />
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className='mt-2'>
                  <p className='ps-lg-4' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > Discover luxurious, Hotel Green Paradise rooms, most with a glamourous Waitematā Harbour view and a stunning lookout of Hotel Green Paradaise Lighter Basin. Our Hotel Green Paradise has 195 luxurious and relaxing rooms and suites, furnished with a modern interior look. </p>
                  <div className='d-flex justify-content-around pt-4 FontFamilyMontSerret'>
                    <NavLink to="/rooms">
                      <button className='p-2 w-100 HOTELORROOMSBOOKNOW'  > <b className='p-lg-3 p-1' style={{ fontWeight: "600" }} > VIEW ROOMS </b> </button>
                    </NavLink>

                    <NavLink to="/hotelbooking">
                      <button className='p-2 w-100 HOTELORROOMSBOOKNOW'  > <b className='p-lg-3 p-1' style={{ fontWeight: "600" }} > BOOK NOW </b> </button>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5 p-lg-4">
          <div className="YESEVA text-lg-center mb-lg-5 mt-lg-5">
            <div className='p-lg-3 pb-3 '  >
              <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "3px" }} > OUR HOTEL </h2>
              <div className='d-flex justify-content-center mt-3'>
                <div className='' style={{ borderTop: "2px solid #633", width: "25%" }}>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-lg-5 g-4 d-flex justify-content-around FontFamilyMontSerret">
            <div className="col-lg-6 col-md-6">
              <div>
                <img className='w-100 ' src={require("../img/pool.png")} alt="emp1" title='emp-1' />
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > HOTEL VADESHWAR </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-lg-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
                <div className='d-flex pt-2 FontFamilyMontSerret'>
                  <NavLink to="/hotelbooking">
                    <button className='p-2 w-100 HOTELORROOMSBOOKNOW'  > <b className='p-3' style={{ fontWeight: "600" }} > BOOK NOW </b> </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5 p-lg-4">
          <div className="row pt-lg-5 g-4 d-flex justify-content-around FontFamilyMontSerret">
            <div className="col-lg-5 col-md-6">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > HOTEL MARATHA </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-lg-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
                <div className='d-flex pt-2 FontFamilyMontSerret'>
                  <NavLink to="/hotelbooking">
                    <button className='p-2 w-100 HOTELORROOMSBOOKNOW'  > <b className='p-3' style={{ fontWeight: "600" }} > BOOK NOW </b> </button>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div>
                <img className='w-100 ' src={require("../img/pool.png")} alt="emp1" title='emp-1' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5 p-lg-4">
          <div className="row pt-lg-5 g-4 d-flex justify-content-around FontFamilyMontSerret">
            <div className="col-lg-6 col-md-6">
              <div>
                <img className='w-100 ' src={require("../img/pool.png")} alt="emp1" title='emp-1' />
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > HOTEL ANIRUDRA </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-lg-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
                <div className='d-flex pt-2 FontFamilyMontSerret'>
                  <NavLink to="/hotelbooking">
                    <button className='p-2 w-100 HOTELORROOMSBOOKNOW'  > <b className='p-3' style={{ fontWeight: "600" }} > BOOK NOW </b> </button>
                  </NavLink>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5 p-lg-4">
          <div className="row pt-lg-5 g-4 d-flex justify-content-around FontFamilyMontSerret">
            <div className="col-lg-5 col-md-6">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > HOTEL AMBALA </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-lg-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
                <div className='d-flex pt-2 FontFamilyMontSerret'>
                  <NavLink to="/hotelbooking">
                    <button className='p-2 w-100 HOTELORROOMSBOOKNOW'  > <b className='p-3' style={{ fontWeight: "600" }} > BOOK NOW </b> </button>
                  </NavLink>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <div>
                <img className='w-100 ' src={require("../img/pool.png")} alt="emp1" title='emp-1' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-5 p-lg-4">
          <div className="row pt-lg-5 g-4 d-flex justify-content-around FontFamilyMontSerret">
            <div className="col-lg-6 col-md-6">
              <div>
                <img className='w-100 ' src={require("../img/pool.png")} alt="emp1" title='emp-1' />
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > HOTEL ABHIRUCHI </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-lg-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
                <div className='d-flex pt-2 FontFamilyMontSerret'>
                  <NavLink to="/hotelbooking">
                    <button className='p-2 w-100 HOTELORROOMSBOOKNOW'  > <b className='p-3' style={{ fontWeight: "600" }} > BOOK NOW </b> </button>
                  </NavLink>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* <section>
        <div className="container pt-5 p-4 ">
          <div className="YESEVA text-lg-center mb-lg-5 mt-lg-5">
            <div className='p-lg-3 pb-3 '  >
              <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "3px" }} > OUR CARS </h2>
              <div className='d-flex justify-content-center mt-3'>
                <div className='' style={{ borderTop: "2px solid #633", width: "25%" }}>
                </div>
              </div>
            </div>
          </div>

          <div className="row pt-lg-5 g-4 d-flex justify-content-around align-items-center FontFamilyMontSerret">
            <div className="col-lg-5">
              <div>
                <img className='w-100 ' src={require("../img/tavera.png")} alt="emp1" title='emp-1' />
              </div>
            </div>
            <div className="col-lg-5">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > POOL </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section>
        <div className="container pt-5 p-4">
          <div className="row pt-lg-5 g-4 d-flex justify-content-around align-items-center FontFamilyMontSerret">
            <div className="col-lg-5">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > POOL </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div>
                <img className='w-100 ' src={require("../img/ertiga.png")} alt="emp1" title='emp-1' />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section>
        <div className="container pt-5 p-4">
          <div className="row pt-lg-5 g-4 d-flex justify-content-around align-items-center FontFamilyMontSerret">
            <div className="col-lg-5">
              <div>
                <img className='w-100 ' src={require("../img/scorpio.png")} alt="emp1" title='emp-1' />
              </div>
            </div>

            <div className="col-lg-5">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > POOL </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
              </div>
            </div>

          </div>
        </div>
      </section> */}

      {/* <section>
        <div className="container pt-5 p-4 mb-5">
          <div className="row pt-lg-5 g-4 d-flex justify-content-around align-items-center FontFamilyMontSerret">
            <div className="col-lg-5">
              <div className='ps-lg-4'>
                <h2 className=' YESEVA' style={{ fontSize: "30px", lineHeight: "1.43", color: "#282828" }} > POOL </h2>
                <div className='d-flex pb-3'>
                  <div className='' style={{ borderTop: "3px solid #633", width: "25%" }}>
                  </div>
                </div>
                <p className='pb-3' style={{ fontSize: "16px", lineHeight: "1.63", color: "#282828" }} > After a busy day of exploring India or a full schedule of business meetings, unwind in our 25 m infinity pool with sweeping views of Hotel Green Paradaise Lighter Basin. Or simply enjoy some sunshine on our pool deck. </p>
              </div>
            </div>

            <div className="col-lg-5">
              <div>
                <img className='w-100 ' src={require("../img/etios.png")} alt="emp1" title='emp-1' />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section>
        <div className="container pt-5 p-lg-4 ">
          <div className="YESEVA  text-lg-center mt-lg-5">
            <div className='p-lg-3 pb-4'  >
              <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "3px" }} > OUR CARS </h2>
              <div className='d-flex justify-content-center mt-3'>
                <div className='' style={{ borderTop: "2px solid #633", width: "25%" }}>
                </div>
              </div>
            </div>
          </div>

          <div className="row p-lg-5 g-4 d-flex justify-content-lg-center align-items-lg-center FontFamilyMontSerret">
            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/tavera.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > TAVERA </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/scorpio.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > SCORPIO </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/ertiga.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > ERTIGA </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/etios.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > ETIOS </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/swift.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > SWIFT DESIRE </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/indica.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > INDICA </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/tempo.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > TRAVELLER </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/minibus.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > MINI BUS (20 SEATER) </h2>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className='p-lg-4  ' >
                <img className='w-100 ' src={require("../img/bus.png")} alt="emp1" title='emp-1' />
                <div className='mt-4 text-lg-center'>
                  <h2 className='' style={{ letterSpacing: "2px", color: "#282828", fontSize: "20px" }} > BUS (40 SEATER) </h2>
                </div>
              </div>
            </div>

          </div>

          <div className='d-flex justify-content-center pt-3 pb-5 FontFamilyMontSerret'>
            <NavLink to="/booking">
              <button className='p-2 w-100 BOOKNOW'  > <b className='p-5' style={{ fontWeight: "400" }} > BOOK NOW </b> </button>
            </NavLink>
          </div>

        </div>
      </section>

    </>
  )
}

export default HotelsOrCars