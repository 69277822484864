import React from 'react'
import { NavLink } from 'react-router-dom'
import "./Footer.css"

const Footer = () => {
    return (
        <>

            <section>
                <div className="container-fluid" style={{ backgroundColor: "#1C2224" }} >
                    <div className="p-lg-5">
                        <div className="row  d-flex justify-content-center pt-4 FontFamilyMontSerret">

                            <div className="col-lg-3">
                                <div className='p-lg-2 '>
                                    <div className=''>
                                        <NavLink to="/">
                                            <img className='' style={{ height: "3.6rem" }} src={require("../img/LOGO2.png")} alt="Green Paradaisesoft_logo" />
                                        </NavLink>
                                    </div>
                                    <div className='row d-flex mt-lg-2  pt-4 pb-4 '>
                                        <div className='col-lg-2 col-3 text-lg-center '>
                                            <NavLink to='' target="_blank"><i className="fa-brands fa-instagram  " style={{ color: "#F26722", fontSize: "40px" }} ></i> </NavLink>
                                        </div>
                                        <div className='col-lg-2 col-3 text-lg-center'>
                                            <NavLink to='' target="_blank"><i className="fa-brands fa-linkedin  " style={{ color: "#F26722", fontSize: "40px" }} ></i> </NavLink>
                                        </div>
                                        <div className='col-lg-2 col-3 text-lg-center '>
                                            <NavLink to="" target="_blank"><i className="fa-brands fa-facebook " style={{ color: "#F26722", fontSize: "40px" }} ></i></NavLink>
                                        </div>
                                        <div className='col-lg-2 col-3 text-lg-center '>
                                            <NavLink to='' target="_blank"><i className="fa-brands fa-twitter  " style={{ color: "#F26722", fontSize: "40px" }} ></i> </NavLink>
                                        </div>
                                    </div>

                                    <div className=''>
                                        <NavLink className="text-decoration-none" to=''>
                                            <div className='mb-2' style={{ color: "#fff", fontSize: "16px", fontWeight: "600" }}> Email  :  <span className='FOOTERHOVER' style={{ fontWeight: "200", }} > greenparadaise.com </span>
                                            </div>
                                        </NavLink>
                                        <NavLink className="text-decoration-none " to=''>
                                            <div className='mb-2' style={{ color: "#fff", fontSize: "17px", fontWeight: "600" }} > Contact :  <span className='FOOTERHOVER' style={{ fontWeight: "200", }} >  +91 9595009267 </span>
                                            </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-1"></div>

                            <div className="col-lg-2 col-md-6">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className='text-white  pb-2' style={{ fontSize: "17px", fontWeight: "600", letterSpacing: "1px" }} > Quick Links </h4>
                                    <div className='pb-3'>
                                        <div className='' style={{ borderTop: "2px solid #fff", width: "50%" }}>
                                        </div>
                                    </div>
                                    <NavLink className='text-decoration-none' to='/' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", }} > Home </h6></NavLink>
                                    <NavLink className='text-decoration-none' to='/hotelorcars' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", }} > Hotel or Cars </h6> </NavLink>
                                    <NavLink className='text-decoration-none' to='/services' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", }} > Our Services </h6> </NavLink>
                                    {/* <NavLink className='text-decoration-none' to='/rooms' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "22px", }} > Rooms </h6> </NavLink> */}
                                    {/* <NavLink className='text-decoration-none' to='/contact' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "22px", }} > Contact </h6> </NavLink> */}
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className='text-white  pb-2' style={{ fontSize: "17px", fontWeight: "600", letterSpacing: "1px" }} > Quick Links </h4>
                                    <div className='pb-3'>
                                        <div className='' style={{ borderTop: "2px solid #fff", width: "50%" }}>
                                        </div>
                                    </div>
                                    {/* <NavLink className='text-decoration-none' to='/' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "22px", }} > Home </h6></NavLink> */}
                                    {/* <NavLink className='text-decoration-none' to='/hotelorcars' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "22px", }} > Hotel or Cars </h6> </NavLink> */}
                                    <NavLink className='text-decoration-none' to='/photo' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", }} > Photos </h6> </NavLink>
                                    <NavLink className='text-decoration-none' to='/rooms' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", }} > Rooms </h6> </NavLink>
                                    <NavLink className='text-decoration-none' to='/contact' > <h6 className='FOOTERHOVER' style={{ fontSize: "16px", lineHeight: "25px", }} > Contact </h6> </NavLink>
                                </div>
                            </div>

                            <div className="col-lg-4">
                                <div className='p-lg-2 pt-3'>
                                    <h4 className='text-white  pb-2' style={{ fontSize: "17px", fontWeight: "600", letterSpacing: "1px" }} > About us</h4>
                                    <div className='pb-3'>
                                        <div className='' style={{ borderTop: "2px solid #fff", width: "30%" }}>
                                        </div>
                                    </div>
                                    <p className='' style={{ color: "#fff", fontSize: "16px" }} >  At Hotel Green Paradaise we believe that every journey should be an enriching and hassle-free experience. Hotel Green Paradaise leading provider of hotel booking , cab booking and car rental services...</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' text-lg-center p-3 text-white ' style={{ backgroundColor: "#0b0d0e", }}>
                    <h6 className='' style={{ fontSize: "15px", color: "#717576", fontWeight: "400" }} > Copyright © Hotel Green Paradaisesoft 2023 </h6>
                </div>
            </section >

        </>
    )
}

export default Footer