import React, { useRef, useState } from 'react'
import "./Booking.css"

function Booking() {

    const [display, setDisplay] = useState("ROUND TRIP");

    const [btn1, setBtn1] = useState("newStyle");

    const [btn2, setBtn2] = useState("btnStyle");


    const changleDisplay = () => {
        setDisplay("ROUND TRIP")
        setBtn1("newStyle")
        setBtn2("btnStyle")
    }

    const changleDisplay2 = () => {
        setDisplay("ONE WAY")
        setBtn1("btnStyle")
        setBtn2("newStyle")
    }


    const form = useRef();

    const [Name, setName] = useState("");

    const [Name1, setName1] = useState("");

    const [Date, setDate] = useState("");

    const [Monumber, setMoNumber] = useState("");

    const [Name2, setName2] = useState("");

    const [Name3, setName3] = useState("");

    const [Date1, setDate1] = useState("");

    const [Date2, setDate2] = useState("");

    const [Monumber1, setMoNumber1] = useState("");

    const [errors, setErrors] = useState({});

    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.Name = ""; errors.Name1 = ""; errors.Date = ""; errors.Monumber = "";

            const user = {
                Name: Name,
                Name1: Name1,
                Date: Date,
                Monumber: Monumber,
            }
            console.log(user);

            var url = "https://wa.me/9527468898?text="
                + " pickup : " + Name + " travelling : " + Name1 + " date : " + Date + " mobile number : " + Monumber;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }

    }

    const validateForm = () => {
        const errors = {};

        if (!Name) {
            errors.Name = "Names Is Requried";
        }
        else if (Name.length < 2) {
            errors.Name = "Names Requried At Least Two Characters"
        }

        if (!Name1) {
            errors.Name1 = "Names Is Requried";
        }
        else if (Name1.length < 2) {
            errors.Name1 = "Names Requried At Least Two Characters"
        }



        if (!Monumber) {
            errors.Monumber = "Mobile Number Is Requried";
        }
        else if (Monumber.length !== 10) {
            errors.Monumber = "Mobile Number Must Have 10 Digit";
        }

        return errors;
    }




    const loginHandle1 = (e) => {
        e.preventDefault();

        const errors = validateForm1();

        if (Object.keys(errors).length === 0) {
            errors.Name2 = ""; errors.Name3 = ""; errors.Date1 = ""; errors.Date2 = ""; errors.Monumber1 = "";

            const user1 = {
                Name2: Name2,
                Name3: Name3,
                Date1: Date1,
                Date2: Date2,
                Monumber1: Monumber1
            }
            console.log(user1);


            // }

            var url = "https://wa.me/9527468898?text="
                + " pickup : " + Name2 + " drop : "  + Name3 + " mobile number : "  +  Monumber1 + " date from : "  +  Date1 + " date to : "  +  Date2;

            window.open(url, '_blank').focus();
        }


        else {
            setErrors(errors);
        }

    }

    const validateForm1 = () => {
        const errors = {};

        if (!Name2) {
            errors.Name2 = "Names Is Requried";
        }
        else if (Name2.length < 2) {
            errors.Name2 = "Names Requried At Least Two Characters"
        }

        if (!Name3) {
            errors.Name3 = "Names Is Requried";
        }
        else if (Name3.length < 2) {
            errors.Name3 = "Names Requried At Least Two Characters"
        }

        if (!Monumber1) {
            errors.Monumber1 = "Mobile Number Is Requried";
        }
        else if (Monumber1.length !== 10) {
            errors.Monumber1 = "Mobile Number Must Have 10 Digit";
        }

        return errors;
    }

    return (
        <>

            <section>
                <div className="container-fluid p-lg-0" >
                    <div className="p-3 mt-5 mb-5 ">
                        <div className="row  d-flex justify-content-center"  >
                            
                            <div className="col-lg-8">
                                <div className="container shadow-lg p-5 FORMWIDTH" style={{ backgroundColor: "#fff" }}>
                                    <div className="row d-flex justify-content-center ">
                                        <div className="row d-flex justify-content-lg-center pb-4" style={{ borderBottom: "1px solid #9e9e9e", width: "90%" }} >
                                            <div className="col-lg-5 col-6  fontFamilyHeader1" style={{ fontSize: "14px" }} >
                                                <button className={btn1} onClick={changleDisplay}> ROUND TRIP </button>
                                            </div>

                                            <div className="col-lg-5 col-6 d-flex  fontFamilyHeader1" style={{ fontSize: "14px" }} >
                                                <button className={btn2} onClick={changleDisplay2}>ONE WAY</button>
                                            </div>

                                        </div>

                                        <div >
                                            {

                                                display === "ONE WAY" ?
                                                    <div className="col-lg-12">
                                                        <div className='p-lg-2 FontFamilyMontSerret' style={{ backgroundColor: "#fff" }} >
                                                            <h6 className='text-center mt-2'> <i class="fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> <span className='ps-2' style={{ color: "#100e0e", fontSize: "16px" }} >Own Way Journey</span> </h6>
                                                            <form ref={form} onSubmit={loginHandle} className="row  g-4 " >
                                                                <div class="col-lg-12 ">
                                                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Pickup from :</label>
                                                                    <input type="text" className="form-control  text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Pickup Location"
                                                                        onChange={e => setName(e.target.value)}
                                                                        value={Name} />
                                                                    {errors.Name && <div className='text-danger'>{errors.Name}</div>}
                                                                </div>

                                                                <div class="col-lg-12 ">
                                                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Travelling to :</label>
                                                                    <input type="text" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                                                        onChange={e => setName1(e.target.value)}
                                                                        value={Name1} />
                                                                    {errors.Name1 && <div className='text-danger'>{errors.Name1}</div>}
                                                                </div>

                                                                <div className='col-lg-12'>
                                                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > Travel Date :</label>
                                                                    <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder="" name="date"
                                                                        onChange={e => setDate(e.target.value)} value={Date} />
                                                                    {errors.Date && <div className='text-danger'>{errors.Date}</div>}
                                                                </div>

                                                                <div class="col-lg-12 ">
                                                                    <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Mobile no :</label>
                                                                    <input type="number" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Mobile Number"
                                                                        onChange={e => setMoNumber(e.target.value)}
                                                                        value={Monumber} />
                                                                    {errors.Monumber && <div className='text-danger'>{errors.Monumber}</div>}
                                                                </div>

                                                                <div className="col-lg-12 pt-2">
                                                                    <button type="submit" className=" p-1 w-100 " style={{ backgroundColor: "#000080", border: "1px solid #000080", color: "#fff", height: "40px", letterSpacing: "1px" }} ><b className='p-lg-5' style={{ fontWeight: "600" }} >Check Rates</b></button>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    : display === "ROUND TRIP" ?
                                                        <div className="col-lg-12">
                                                            <div className='p-lg-2 FontFamilyMontSerret' style={{ backgroundColor: "#fff" }} >
                                                                <h6 className='text-center mt-2'> <i class="fa-sharp fa-light fa-mobile-button" style={{ fontSize: "20px" }} ></i> <span className='ps-2' style={{ color: "#100e0e", fontSize: "16px" }} >Round the Trip Journey</span> </h6>
                                                                <form ref={form} onSubmit={loginHandle1} className="row g-4 " >
                                                                    <div class="col-lg-12 ">
                                                                        <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Pickup from :</label>
                                                                        <input type="text" className="form-control  text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Pickup Location"
                                                                            onChange={e => setName2(e.target.value)}
                                                                            value={Name2} />
                                                                        {errors.Name2 && <div className='text-danger'>{errors.Name2}</div>}
                                                                    </div>

                                                                    <div class="col-lg-12 ">
                                                                        <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Travelling to :</label>
                                                                        <input type="text" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Drop Location"
                                                                            onChange={e => setName3(e.target.value)}
                                                                            value={Name3} />
                                                                        {errors.Name3 && <div className='text-danger'>{errors.Name3}</div>}
                                                                    </div>

                                                                    <div className='col-lg-12'>
                                                                        <div className='row g-2'>
                                                                            <div className="col-lg-6">
                                                                                <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > From To :</label>
                                                                                <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder=""
                                                                                    onChange={e => setDate1(e.target.value)} value={Date1} />
                                                                                {errors.Date1 && <div className='text-danger'>{errors.Date1}</div>}
                                                                            </div>

                                                                            <div className="col-lg-6">
                                                                                <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} > To :</label>
                                                                                <input type="date" className="form-control   text-dark h1FontFamily  " style={{ borderRadius: "0px 0px 0px 0px" }} placeholder=""
                                                                                    onChange={e => setDate2(e.target.value)} value={Date2} />
                                                                                {errors.Date2 && <div className='text-danger'>{errors.Date2}</div>}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12 ">
                                                                        <label htmlFor="" className='pb-2' style={{ color: "#8e2600", fontSize: "13px" }} >Mobile no :</label>
                                                                        <input type="number" className="form-control   text-dark h1FontFamily1 " style={{ borderRadius: "5px 0px 0px 5px" }} placeholder="Mobile Number"
                                                                            onChange={e => setMoNumber1(e.target.value)}
                                                                            value={Monumber1} />
                                                                        {errors.Monumber1 && <div className='text-danger'>{errors.Monumber1}</div>}
                                                                    </div>

                                                                    <div className="col-lg-12 pt-2">
                                                                        <button type="submit" className=" p-1 w-100 " style={{ backgroundColor: "#000080", border: "1px solid #000080", color: "#fff", height: "40px", letterSpacing: "1px" }} ><b className='p-lg-5' style={{ fontWeight: "600" }} >Check Rates</b></button>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>

                                                        : <div>
                                                            somethings went wrong
                                                        </div>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </section>

        </>
    )
}

export default Booking