import React from 'react'
import { NavLink } from 'react-router-dom'

const Rooms = () => {
  return (

    <>

      <section>
        <div className='container-fluid text-white p-0 ALLBG' style={{ backgroundImage: `url(${require("../img/roomBG.jpg")})`, backgroundAttachment: "local", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: 'center center ' }}>
        </div>
      </section>

      <section>
        <div className="container-fluid">
          <div className="container pb-lg-4 mb-lg-5 p-lg-4 pt-4 FontFamilyMontSerret" >
            <div className="row pt-lg-5 g-4 d-flex justify-content-around">
              <div className="col-lg-5 col-md-6">
                <div>
                  <h2 className='ps-lg-4 mb-3 YESEVA' style={{ fontSize: "2.6rem" }} >Rooms + Suites</h2>
                  <p className='ps-lg-4 FONTSIZE' style={{  lineHeight: "1.63", color: "#282828" }} > Discover 5-star hotel rooms and suites at Hotel Green Paradaise, Chikhaldara where you immerse in the world of luxury, style and elegance. Explore our spacious and comfortable rooms – ideal for holiday goers, business travellers and families to relax after a long day of exploring India. Our rooms and suites are designed with floor to ceiling windows for the perfect view – some rooms have stunning views across Hotel Green Paradaise Lighter Basin</p>
                  <div className='d-flex justify-content-lg-around pt-3 FontFamilyMontSerret'>
                    <NavLink  to="/hotelorcars">
                      <button className='p-2 HOTELORROOMSBOOKNOW '  > <b className=' p-1' style={{ fontWeight: "600" }} > VIEW ROOMS </b> </button>
                    </NavLink>

                    <NavLink className='ms-2' to="/hotelorcars">
                      <button className='p-2  HOTELORROOMSBOOKNOW'  > <b className=' p-1' style={{ fontWeight: "600" }} > BOOK NOW </b> </button>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6">
                <div>
                  <img className='w-100' src={require("../img/room1.png")} alt="emp1" title='emp-1' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-lg-5 mt-lg-5 p-lg-2">
          <div className="YESEVA ps-lg-5">
            <div className='p-3 pb-3 mt-2'  >
              <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "2px" }} > OUR ROOMS </h2>
              <div className='mt-3'>
                <div className='' style={{ borderTop: "2px solid #633", width: "18%" }}>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-around g-4 p-3">
            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room2.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >1 King Bed</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}>This elegantly appointed 47 sqm room features one king bed, a walk in wardrobe, a living area and marble bathroom with separate walk in shower and deep soaking bath.</p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room3.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >1 King Bed with Balcony</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}> Escape to a 47 sq m haven of luxury featuring one king bed, a spacious living area with a private 10 sq m furnished balcony and a marble bathroom </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-around g-4 p-3">
            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room4.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >2 Twin Beds</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}> This elegantly appointed 57 sqm room features two king single beds, separate walk in wardrobes, a living area and marble bathroom with separate walk in shower and deep soaking bath.</p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room5.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >1 King Bed Harbour View</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}> Enjoy panoramic views of India in a 47 sqm room featuring one king bed, a spacious living area and a marble bathroom. </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-around g-4 p-3">
            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room6.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >2 Twin Beds Harbour View</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}>Enjoy panoramic views of the Hotel Green Paradaise in a 57 sqm room featuring two king single beds, a spacious living area and a marble bathroom.</p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room7.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >1 King Bed Deluxe</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}> Escape to a 65 sqm haven of luxury featuring one king bed, a spacious living area and a marble bathroom with separate walk in shower and deep soaking bath. Perfect for families to enjoy a comfortable stay. </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-around g-4 p-3">
            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room8.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >1 King Bed Harbour View with Balcony</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}>Escape to a 47 sq m haven of luxury featuring one king bed, a spacious living area with a private 10 sq m furnished balcony and a marble bathroom. </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room9.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >1 King Bed Rooftop with Balcony</h2>
                <p className='pt-3' style={{ fontSize: "18px", color: "#282828", lineHeight: "1.69" }}> Escape to a 65 sqm haven of luxury featuring one king bed, a spacious living area and a marble bathroom with separate walk in shower and deep soaking bath. Perfect for families to enjoy a comfortable stay. </p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section>
        <div className="container p-lg-2">
          <div className="YESEVA pb-lg-4 ps-lg-5">
            <div className='p-3'  >
              <h2 className='' style={{ color: "#282828", fontSize: "25px", fontWeight: "800", letterSpacing: "2px" }} > SUITES </h2>
              <div className='mt-3'>
                <div className='' style={{ borderTop: "2px solid #633", width: "15%" }}>
                </div>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-around g-4 p-3">
            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/suite1.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} > Park Suite </h2>
                <p className='pt-3' style={{ fontSize: "15px", color: "#282828", lineHeight: "1.69" }}> This premium 71 sqm corner suite offers one king bed, a dining area, spacious marble bathroom with rain shower and separate soaking tub, walk in wardrobe and a private 8 sqm furnished balcony. Connecting room is available to create a 2 bedroom suite. </p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/suite1.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >Harbour Suite</h2>
                <p className='pt-3' style={{ fontSize: "15px", color: "#282828", lineHeight: "1.69" }}> This premium 71 sqm corner suite offers panoramic views of the Hotel Green Paradaise and India’s city skyline on a high-level floor, a dining area, spacious marble bathroom with rain shower and separate soaking tub, walk in wardrobe and a private 8 sqm furnished balcony. Connecting room is available to create a 2 bedroom suite.</p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-around g-4 p-3">
            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room4.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >Executive Suite</h2>
                <p className='pt-3' style={{ fontSize: "15px", color: "#282828", lineHeight: "1.69" }}> Feel at home and unwind in this spacious 127 sqm suite with views of the Hotel Green Paradaise and India’s city skyline, one king bed, separate living area. This specialty suite features a large marble bathroom with separate powder room, walk in wardrobe and a private 14 sqm furnished balcony.</p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room5.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >Executive Rooftop Suite</h2>
                <p className='pt-3' style={{ fontSize: "15px", color: "#282828", lineHeight: "1.69" }}> This spacious 135 sqm corner suite on the top floor offers a furnished wrap around balcony with Harbour bridge and city views, a king bed, dining area, spacious marble bathroom with rain shower and separate soaking tub. Connecting room is available to create a 2-bedroom suite. </p>
              </div>
            </div>
          </div>

          <div className="row d-flex justify-content-around g-4 p-3">
            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room6.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >Chairman Suite</h2>
                <p className='pt-3' style={{ fontSize: "15px", color: "#282828", lineHeight: "1.69" }}> This expansive 141 sqm corner suite on the top floor of the hotel, offers views of the Hotel Green Paradaise and city skyline, one king bed, separate living and dining areas, full designer kitchen, marble bathroom with separate powder room and a private 106 sqm furnished balcony. Two bedroom option available with connecting rooms.</p>
              </div>
            </div>

            <div className="col-lg-5 col-md-6">
              <img className='w-100' src={require("../img/room7.png")} alt="emp1" title='emp-1' />
              <div className='mt-2 pt-3'>
                <h2 className='YESEVA' style={{ fontSize: "24px", color: "#633" }} >Presidential Suite</h2>
                <p className='pt-3' style={{ fontSize: "15px", color: "#282828", lineHeight: "1.69" }}>  Located on the top floor of the hotel, this suite features a private outdoor garden, 125 sqm furnished balcony, a king bed, separate living and dining areas, fully equipped designer kitchen, internal boardroom for private meetings or dining, marble bathroom with separate powder room and secure VIP access. Two bedroom option available with connecting rooms. </p>
              </div>
            </div>
          </div>

        </div>
      </section>

    </>
  )
}

export default Rooms