import React, { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom';
import "./HotelBooking.css"

const HotelBooking = () => {

    const form = useRef();

    const [name, setName] = useState("");

    const [number, setNumber] = useState("");

    const [phone, setPhone] = useState("");

    const [email, setEmail] = useState("");

    const [massage, setMassage] = useState("");

    const [errors, setErrors] = useState({});


    const loginHandle = (e) => {
        e.preventDefault();

        const errors = validateForm();

        if (Object.keys(errors).length === 0) {
            errors.name = ""; errors.number = ""; errors.phone = ""; errors.email = ""; errors.massage = "";

            var url = "https://wa.me/9527468898?text="
                + " NAME : " + name + " NUMBER : " + number + " Phone : " + phone + " EMAIL : " + email + " MASSAGE : " + massage;

            window.open(url, '_blank').focus();
        }

        else {
            setErrors(errors);
        }
    }


    const validateForm = () => {
        const errors = {};
        // console.log(errors)
        var mailformat = /\S+@\S+\.\S+/;

        if (!name) {
            errors.name = "Names Is Requried";
        }
        else if (name.length < 4) {
            errors.name = "Names Requried At Least Four Characters"
        }



        if (!phone) {
            errors.phone = "Number Is Requried";
        }
        else if (phone.length !== 10) {
            errors.phone = "Number Must Have 10 Digit";
        }

        if (!email) {
            errors.email = "Email Is Requried";
        }
        if (email) {
            if (email.match(mailformat)) {

            }
            else {
                errors.email = `Email Must Be In " Text + @ + Text + . + Text " Format`
            }
        }

        if (!massage) {
            errors.massage = "Massage Is Requried";
        }
        return errors;
    }


    return (

        <>



            <section>
                <div className="container-fluid">
                    <div className="container p-lg-5">
                        <div className="row pb-lg-5" style={{ borderBottom: "1px solid #e5e5e5" }}>

                            <div className="col-lg-12">
                                <div className="row d-flex justify-content-around ">

                                    <div className='col-lg-5' >
                                        <div className='p-lg-2 mt-lg-4'>
                                            <div>
                                                <h6 className='pb-2 YESEVA' style={{ fontSize: "25px", color: "#282828" }} >Contact Address</h6>
                                                <p className='pb-3' style={{ fontSize: "17px", lineHeight: "1.6" }} >Call for Hotel Booking and Car Rental , Taxi for  Outstation <br /> Tour or for Local  Travel.We have   wide range of  <br /> Vehicles with us for your service.</p>
                                            </div>

                                            <div className='mb-3 pb-1'>
                                                <h4 className='pb-2 YESEVA' style={{ fontSize: "20px", fontWeight: "800", color: "#282828", letterSpacing: "1px" }} >Address : </h4>
                                                <h6 className='FontFamilyMontSerret' style={{ fontSize: "16px", lineHeight: "1.8" }} > Hotel Green Paradaise <br /> Chikhaldara   ( Badnera ) , Amravati 444807 </h6>
                                            </div>

                                            <div className='mt-3 pb-2'>
                                                <span className='YESEVA' style={{ fontSize: "20px", fontWeight: "800", color: "#282828", letterSpacing: "1px" }} >Email :
                                                    <NavLink className='text-decoration-none text-dark FontFamilyMontSerret' to="">  <span className='ps-1' style={{ fontSize: "17px", fontWeight: "400" }} > greenparadaise.com </span></NavLink>
                                                </span>
                                            </div>

                                            <div className='mt-3 mb-2' >
                                                <span className='YESEVA' style={{ fontSize: "20px", fontWeight: "800", color: "#282828", letterSpacing: "1px" }} >Mobile No :
                                                    <NavLink className='text-decoration-none text-dark FontFamilyMontSerret' to="">  <span className='ps-1' style={{ fontSize: "17px", fontWeight: "400" }} > +91 9595009267 </span> </NavLink>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-lg-6">

                                        <div className='row mt-lg-3 mb-3' >

                                            <div className="col text-dark">
                                                <div className=' '  >

                                                    <form ref={form} onSubmit={loginHandle} className="row g-4  " style={{ borderRadius: "0px 0px 0px 0px" }}>
                                                        <h5 className='YESEVA' style={{ fontSize: "34px", fontWeight: "400" }} > Booking Now </h5>
                                                        <h5 className='FontFamilyMontSerret' style={{ fontWeight: "300",fontSize:"15px" }}>Please fill your details and we will get back to you shortly.</h5>
                                                        <div class="col-lg-12 ">
                                                            <div className='row g-4'>
                                                                <div className="col-lg-6">
                                                                    <input type="text" className="form-control   text-dark FontFamilyMontSerret  " style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#fff", border: "1px solid #e5e5e5", height: "50px" }} id="inputName" placeholder="Name:" name="name"
                                                                        onChange={e => setName(e.target.value)} value={name}
                                                                    />
                                                                    {errors.name && <div className='text-danger'>{errors.name}</div>}
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <input type="text" className="form-control   text-dark FontFamilyMontSerret" minLength='10' maxLength='10' style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#fff", border: "1px solid #e5e5e5", height: "50px" }} id="inputPhone" placeholder="Phone:" name="phone"
                                                                        onChange={e => setPhone(e.target.value)} value={phone}
                                                                    />
                                                                    {errors.phone && <div className='text-danger'>{errors.phone}</div>}
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className='col-lg-12'>
                                                            <div className='row g-4'>
                                                                <div className="col-lg-6">
                                                                    <input type="number" className="form-control   text-dark FontFamilyMontSerret  " minLength='10' maxLength='10' style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#fff", border: "1px solid #e5e5e5", height: "50px" }} id="inputNumber" placeholder="Number:" name="number"
                                                                        onChange={e => setNumber(e.target.value)} value={number}
                                                                    />
                                                                    {errors.number && <div className='text-danger'>{errors.number}</div>}
                                                                </div>

                                                                <div className="col-lg-6">
                                                                    <input type="email" className="form-control boderRadius  text-dark FontFamilyMontSerret" style={{ borderRadius: "0px 0px 0px 0px", color: "#777", backgroundColor: "#fff", border: "1px solid #e5e5e5", height: "50px" }} id="inputEmail4" placeholder="Email:" name="email"
                                                                        onChange={e => setEmail(e.target.value)} value={email}
                                                                    />
                                                                    {errors.email && <div className='text-danger'>{errors.email}</div>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12">
                                                            <textarea type="text" className="form-control   text-dark FontFamilyMontSerret" style={{ borderRadius: "0px 0px 0px 0px", height: "155px" }} id="inputText3" placeholder="Message" name="message"
                                                                onChange={e => setMassage(e.target.value)} value={massage}
                                                            />
                                                            {errors.massage && <div className='text-danger'>{errors.massage}</div>}
                                                        </div>

                                                        <div className="col-lg-12 ">
                                                            <button type="submit" className=" p-1 SendButton FontFamilyMontSerret"  ><b className='p-lg-2 fs-5' style={{ fontWeight: "300" }}> Book Now </b></button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>

    )
}

export default HotelBooking